import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  HeaderMainContainer,
  HeaderMenuContainer,
  HeaderTitle,
  HeaderUserMenuContainer,
  LabelUserText,
  LogOutButton,
  NotificationBell,
} from "./Header.styled";
import { connectStore } from "redux-box";
import { module as onboard } from "@st/onboard";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as payment } from "@st/payment";
import { module as misc } from "@st/misc";
import { module as inviteCode } from "@st/inviteCode";
import Pravatar from "pravatar";
import Permission from "@s/permission";
const getTitle = () => {
  return [
    {
      title: "Users",
      path: "/users",
    },
    {
      title: "Notifications",
      path: "/notifications",
    },
    {
      title: "Experiences",
      path: "/experiences",
    },
    {
      title: "Stories",
      path: "/stories",
    },
    {
      title: "Activities",
      path: "/activities",
    },
    {
      title: "Case Studies",
      path: "/case-studies",
    },
    {
      title: "Assessments",
      path: "/assessments",
    },
    {
      title: "Reading Challenge",
      path: "/reading-challenge",
    },
    {
      title: "News Freads",
      path: "/news-freads",
    },
    {
      title: "Payments",
      path: "/payments",
    },
    {
      title: "Invite Code",
      path: "/invite-code",
    },
    {
      title: "Misc",
      path: "/flive-code",
    },
    {
      title: "Leader Board",
      path: "/leader-board",
    },
    {
      title: "Skills",
      path: "/tags",
    },
    {
      title: "Quiz2",
      path: "/quiz2",
    },
    {
      title: "Guided Path",
      path: "/product",
    },
  ];
};

// export default connectStore({
//   onboard,
//   activity,
//   ui,
//   payment,
//   misc,
// })

class Header extends Component {
  componentDidMount() {
    let { payment, inviteCode, activity } = this.props;
    payment.requestPlanInfo();
    activity.requestGrades();
    // inviteCode.getSchoolList();
  }
  // componentDidUpdate(prevProps) {
  //   if (
  //     this.props.inviteCode.adminSchoolList !==
  //     prevProps.inviteCode.adminSchoolList
  //   ) {
  //     if (
  //       this.props.inviteCode.schoolListResult &&
  //       this.props.inviteCode.schoolListResult.has_next
  //     ) {
  //       setTimeout(() => {
  //         if (
  //           this.props.inviteCode.schoolListResult !== null &&
  //           this.props.inviteCode.schoolListResult.next_page > 0
  //         )
  //           this.props.inviteCode.getSchoolList(
  //             this.props.inviteCode.schoolListResult.next_page
  //           );
  //       }, 300);
  //     }
  //   }
  // }

  handleLogoutBtn = () => {
    this.props.onboard.setLogin(null);
    localStorage.clear();
    this.props.history.push("/login");
  };
  render() {
    let { user, permission } = this.props.onboard;
    let title = getTitle().map((option) => {
      if (
        window.location.pathname.match(option.path) ||
        window.location.pathname.includes(option.path)
      )
        return option.title;
      return "";
    });

    return (
      <HeaderMainContainer>
        <HeaderTitle>
          {window.location.pathname === "/" ? "Users" : title}
        </HeaderTitle>
        <HeaderUserMenuContainer>
          <Pravatar
            image={user.image}
            text={user.image ? "" : user.name.slice(0, 1)}
            bg="#e7e7e7"
            border="7px solid #341744"
            textColor="#341744"
          />
          <HeaderMenuContainer primary>
            <LabelUserText primary> Hello! </LabelUserText>
            <LabelUserText> {user.name}</LabelUserText>
          </HeaderMenuContainer>
          {Permission.getPermissionForMenu(permission, ["plan"]) ? (
            <HeaderMenuContainer primary>
              <NotificationBell onClick={() => this.props.ui.showModal("PLAN")}>
                <i className="fa fa-rupee" />
              </NotificationBell>
            </HeaderMenuContainer>
          ) : null}
          {Permission.checkRole(permission, "superuser") ? (
            <NotificationBell
              onClick={() => this.props.misc.requestAnalyticsStory()}
            >
              <i className="fa fa-star" />
            </NotificationBell>
          ) : null}
          <LogOutButton onClick={this.handleLogoutBtn}>
            <i className="fa fa-power-off" />
          </LogOutButton>
        </HeaderUserMenuContainer>
      </HeaderMainContainer>
    );
  }
}
export default withRouter(
  connectStore({
    onboard,
    activity,
    ui,
    payment,
    misc,
    inviteCode
  })(Header)
);
// export default withRouter(Header);
